import request from "../../util/request";

/**
 * 
 * @param {Object} query { uuid: String}
 * @returns 
 */
export function rankList (query) {
    return request.post('/keleScore/getRank', query);
}

export function getUserScore (query) {
    return request.post('/keleScore/getUserScore', query);
}

export function getCurrentScore (query) {
    return request.post('/keleScore/getCurrentScore', query);
}


export function reportScore(query){
    return request.post('/keleScore/calculateScore', query);
}

export function reportRoomScore (query) {
    return request.post('/keleRoom/calculateScore', query);
}

export function queryRoomInfo (query) {
    return request.post('/keleRoom/roomInfo', query);
}

export function participate(query){
    return request.post('/keleRoom/participate', query);
}

export function stake (query) {
    return request.post('/keleRoom/stake', query);
}

export function roomRank(query){
    return request.post('/keleRoom/roomRank', query);
}

export function resurrection(query){
    return request.post('/keleScore/resurrection',query);
}