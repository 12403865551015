import axios from "axios";//创建一个axios的对象
import store from "../store/index"
import { Toast } from 'vant';


//生成一个axios的实例
const http = axios.create({
    // baseURL: process.env.NODE_ENV === 'development' ? window._$api.devUrl : window._$api.proUrl, // url = base url + request url
    baseURL: process.env.NODE_ENV === 'development' ? "http://47.99.176.18:9999" : "https://api.kelemetaverse.com", // url = base url + request url
    timeout: 1000 * 60 // request timeout
});
export default http;//导出

let loading
function startLoading () {
    // loading = Toast.loading({
    //     // message: '加载中...',
    //     forbidClick: true,
    // })
}

function endLoading () {
    // loading.clear()
}

// request interceptor
http.interceptors.request.use(
    config => {
        startLoading();
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.headers['Content-Type'] = 'application/json'
        console.log(store.state.token)
        config.headers['Authorization'] = store.state.token
        return config
    },
    error => {
        return Promise.reject(error)
    }
)


http.interceptors.response.use(
    response => {
        endLoading();
        return response.data
    },
    error => {
        endLoading();
        return Promise.reject(error);
    }
)