<template>
    <div>
        <!-- <van-overlay :show="isShow" style="background-color:rgba(0,0,0,0.95)" :lock-scroll="false"> -->
        <van-overlay :show="isShow" style="background-color:rgba(0,0,0,0);" :lock-scroll="false">
            <img src="../../assets/image/gameover_bg.jpg" style="z-index:-1;position:fixed;top:0;left:0;width: 100%;height: 100%;"/>
            <div class="scroll-wrapper" ref="scroll" :style="{height:wrapperHeight}">
                <div class="scroll-content" :style="{height:contentHeight}">
                    <div class="firstLayout" ref="firstLayout">
                        <img class="flag_img" :src="getFlag()"/>
                        <div class="title">
                            {{ $t('game.scoreTitle')}}
                        </div>
                        <img class="sharebtn" src="../../assets/image/ic_share_icon.png" @click="toShare" />
                        <div class="score_item">
                            <div>{{ $t('game.currentScore')}}</div>
                            <div style="color:#fff">{{ score.currentScore == '-1' ? "-" : score.currentScore }}/
                                <span style="color:black">{{ score.currentRank == '-1' ? "-" : score.currentRank }}</span>
                            </div>
                        </div>
                        <div class="score_item">
                            <div>{{ $t('game.bestScore')}}</div>
                            <div style="color:#fff">{{ score.maxScore == '-1' ? "-" : score.maxScore }}/
                                <span style="color:black">{{ score.maxRank == '-1' ? "-" : score.maxRank }}</span>
                            </div>
                        </div>
                        <div class="score_item" @click="toCountry">
                            <div>{{ $t("game.mycountry.first")}}<span style="color:white">{{ score.country }}</span>{{
                            $t("game.mycountry.second")}}</div>
                            <div style="color:black">{{ score.countryRank == '-1' ? "-" : score.countryRank }}</div>
                        </div>
                        <div class="score_item" @click="toCity">
                            <div>{{ $t("game.mycity.first")}}<span style="color:white">{{ score.city }}</span>{{
                            $t("game.mycity.second")}}
                            </div>
                            <div style="color:black">{{ score.cityRank == '-1' ? "-" : score.cityRank }}</div>
                        </div>
                        <div class="score_item" @click="toMyRank">
                            <div>{{ $t("game.myrank.first")}}<span style="color:white">{{ score.city }}</span>{{
                            $t("game.myrank.second")}}
                            </div>
                            <div style="color:black">{{ score.myCityRank == '-1' ? "-" : score.myCityRank }}</div>
                        </div>
                        <div class="next_notice">
                            {{ $t("game.jumpContinue.first")}} {{ score.moreSteps }}{{ $t("game.jumpContinue.second") }}{{ score.advance
                            }}{{ $t("game.jumpContinue.third") }}
                        </div>
                        <van-button round class="fuhuo" @click="resurrect"><span style="margin-left:27px">{{
                        $t('game.gameOverRe')}}</span><span>({{ score.retryTimes }})</span></van-button>
                        <div class="btn_white" @click="restart">{{ $t('game.gameOverRestart')}}</div>
                        <div class="btn_white" @click="endGame">{{ $t('game.gameOverEnd')}}</div>
                    </div>
                    
                    <div class="secondLayout" ref="secondLayout">
                        <item v-for="(rank,index) in score.rankInfo.topRank" :key="index" :topRank="rank" :index="index" :isRed="true"></item>
                    </div>
                </div>
            </div>

        </van-overlay>
    </div>

</template>

<script type="text/ecmascript-6">
    import Swiper from "swiper"
    import item from '../rank/SortItem.vue'
    import { resurrection } from '../../api/tetris/api'
    import BScroll from '@better-scroll/core'


    export default{
        components: { item },
        mounted () {
            // this.myswiper()
            // this.init()
        },
        props:{
            score:{
                type:Object,
                defalut:function(){
                    return {}
                }
            }
        },
        watch: {
            'isShow': {
                handler (newVal) {
                    this.$nextTick(() => {
                        if (this.bs) {
                            this.bs.refresh()
                        }
                    })
                },
                deep: true,
                immediate: false
            }
        },
        data(){
            return {
                isShow:false,
                contentHeight:"0px",
                wrapperHeight:"0px",
            }
        },
        beforeDestroy () {
            if (this.bs){
                this.bs.destroy()
            }
            
        },
        methods:{
            init () {
                // this.bs = new BScroll(this.$refs.scroll, {
                //     probeType: 3,
                //     click: true
                // })
            },
            myswiper: function () {
                console.log("123")
                this.swiper = new Swiper(this.$refs.swiper, {
                    direction: "vertical",
                    slidesPerView: "auto",
                    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, // 修改swiper的父元素时，自动初始化swiper
                    freeMode: true,
                    scrollbar: {
                        el: ".swiper-scrollbar",
                        hide:true
                    },
                    mousewheel: true,
                });
                console.log(swiper)
            },
            getFlag(){
                console.log("../../assets/image/flag/" + this.score.isoCode + ".png")
                return require("../../assets/image/flag/" + this.score.isoCode +".png")
            },
            toCountry(){
                uni.navigateTo({
                    url:"/pages/countryrank/countryrank"
                })
            },
            toCity () {
                uni.navigateTo({
                    url: "/pages/cityList/cityList?type=1&countryId=" + this.score.country
                })
            },
            toMyRank(){
                uni.navigateTo({
                    url: "/pages/cityList/cityList?type=2&cityId=" + this.score.city + "&countryId=" + this.score.country
                })
            },
            showGameOver(){
                this.isShow = true
                console.log("111111111")
                this.$nextTick(()=>{
                    console.log(this.$refs.firstLayout)
                    this.contentHeight = (this.$refs.firstLayout.offsetHeight+this.$refs.secondLayout.offsetHeight+350)+"px";
                    this.wrapperHeight = (window.innerHeight) +"px"
                    console.log("gaodu", this.contentHeight, window.getComputedStyle(this.$refs.firstLayout).height + window.getComputedStyle(this.$refs.secondLayout).height)
                    if (!this.bs){
                        this.bs = new BScroll(this.$refs.scroll, {
                            click: true,
                        })
                        setTimeout(()=>{
                            this.bs.refresh()
                        },1000)
                        console.log(this.bs)
                    }else{
                        this.bs.refresh()
                    }
                    // if (!this.swiper){
                    //     this.myswiper()
                    // }
                })
            },
            endGame(){
                uni.navigateBack({
                    delta: 1
                });
            },
            restart(){
                this.isShow = false
                this.$parent.$parent.restart()
            },
            resurrect(){
                if (this.score.retryTimes > 0){
                    resurrection({ gameType: this.$gameType, difficulty: this.$difficulty }).then(response => {
                        if(response.code == "200"){
                            this.score.retryTimes = this.score.retryTimes - 1
                            this.$parent.updateRetryTime(this.score.retryTimes)
                            this.isShow = false
                            this.$parent.$parent.resurrect()
                        }else{
                            this.score.retryTimes = 0
                            this.$parent.updateRetryTime(0)
                        }
                    })
                }else{
                    //去购买
                }
            },
            toShare(){
                let tempScore = {
                    currentScore: this.score.currentScore,
                    currentRank: this.score.currentRank,
                    maxScore: this.score.maxScore,
                    maxRank: this.score.maxRank,
                    country: this.score.country,
                    countryRank: this.score.countryRank,
                    city: this.score.city,
                    cityRank: this.score.cityRank,
                    myCityRank: this.score.myCityRank,
                    countryIOS: this.score.isoCode,
                }
                uni.navigateTo({
                    url: "/pages/gameshare/gameshare?score=" + encodeURIComponent(JSON.stringify(tempScore))
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import url("../../../node_modules/swiper/swiper.min.css");
.topLayout{
    width: 100%;
    display: flex;
    margin-top: 3.625rem;
    flex-direction: row-reverse;
    // justify-content: right;
    
}


.scroll-wrapper{
    margin-top: 1.8125rem;
    height:61.125rem;
    // position:relative; 
    overflow:hidden;

    .scroll-item {
        height:50px;
        line-height:50px;
        font-size:24px;
        font-weight:bold;
        border-bottom :1px solid #eee;
        text-align:center;

    }
}

// .scroll-content{
//     min-height: 62.5rem;
// }

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    // font-size: 18px;
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: 6.25rem;
}

.firstLayout{
    color:black;
    border-radius: .625rem;
    background-color: #838383;
    margin: .625rem 1rem;
    padding: 1rem;
    background-image: url("../../assets/image/gameover_top_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    .flag_img{
        position: absolute;
        width: 1.875rem;
        height: 1.875rem;
        left:2rem;
    }


    .sharebtn {
        width: 1.875rem;
        height: 1.875rem;
        position: absolute;
        right: 2rem;
        margin-top: -2rem;
    }


    .title{
        margin: auto;
        font-size: 1.25rem;
        font-weight: bold;
    }

    .score_item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1rem;
        font-size: 1.125rem;
        font-weight: bold;
    }

    .next_notice{
        color:black;
        font-size: 1.125rem;
        margin-top: 1rem;
        font-weight: bold;
    }

    .fuhuo{
        width: 12.5rem;
        height: 2.5rem;
        background-color: #ddfc48;
        border-width: 0rem;
        color: black;
        font-size: 1.125rem;
        font-weight: bold;
        margin-top: 1rem;
    }

    .btn_white{
        color: black;
        font-size: 1.125rem;
        font-weight: bold;
        margin-top: 1rem;
    }
}

.secondLayout{
    margin: .625rem 1rem;
}

// .content{
//     height:100%;
//     overflow-y:scroll; // 惯性滚动效果
//     -webkit-overflow-scrolling: touch;
// }
</style>