import { Base64 } from "js-base64";

export function guid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function handleNonce(uuid){
    let content = uuid +"/"+(new Date().getTime())
    return Base64.encode(content)
}

export function handleRoomNonce (roomId,uuid) {
    let content = roomId+"/"+ uuid + "/" + (new Date().getTime())
    return Base64.encode(content)
}

export function serverTimeToLocal(serverTime){
    // console.log(serverTime)
    var offset = new Date().getTimezoneOffset() * 60000;
    // console.log(new Date().getTimezoneOffset(), offset)
    var localTime = serverTime + offset;
    // console.log(localTime)
    return new Date(localTime)
}