<template>
    <div class="app">
        <div class="top" v-if="showTop">
            <img src="@/assets/image/ic_back.png" class="back" @click="back()"/>

            <van-row class="center">
                <van-col span="8" class="top_line" >
                    <img src="../../assets/image/ic_fhcount.png"/>
                    <div>{{ retryTimes }}</div>
                </van-col>
                <van-col span="8" class="top_line">
                    <img src="../../assets/image/ic_score.png" />
                    <div>{{ gameScore }}</div>
                </van-col>
                <van-col span="8" class="top_line">
                    <img src="../../assets/image/ic_glod.png" />
                    <div>{{ goldScore }}</div>
                </van-col>
            </van-row>

            <img src="@/assets/image/ic_play_rank.png" @click="toRank" class="rank" />
        </div>

        <div class="score" v-if="false">
            <div>{{ lines }}</div>
            <div>{{ currentGoldScore }}</div>
        </div>
    
        <RankDialog ref="rankDialog"></RankDialog>
        
        <gameOver :score="score" ref="gameOver"></gameOver>
    </div>

</template>

<script>
    
    import RankDialog from '../rank/RankDialog.vue'
    import gameOver from './SingleGameOver.vue'
    import { getUserScore, reportScore, getCurrentScore } from '../../api/tetris/api'
    import { guid, handleNonce } from '../../util/util'

    export default{
        name:"single",
        components: { RankDialog, gameOver },
        data(){
            return {
                showRank:false,
                retryTimes:0,
                gameScore:0,
                goldScore:0,
                lines:0,
                currentGoldScore:0,
                uuid:"",
                score:{},
                showTop:true,
                currentSteps:0
            }

        },
        mounted(){
            this.updateUserScore()
        },
        methods:{
            back(){
                uni.navigateBack({
                    delta: 1
                });
            }, 
            toRank(){
                // this.showRank = false
                // this.showRank = true
                this.$refs.rankDialog.showRank()
            },
            updateUserScore(){
                getUserScore({ gameType: this.$gameType, difficulty: this.$difficulty }).then(response =>{
                    if(response.code == 200){
                        this.retryTimes = response.data.retryTimes
                        this.gameScore = response.data.gameScore
                        this.goldScore = response.data.goldScore
                    }
                })
            },
            startGame(){
                this.showTop = false
                this.uuid = guid()
            },
            setGameOver(){
                this.updateUserScore()
                getCurrentScore({ gameType: this.$gameType, difficulty: this.$difficulty,nonce:handleNonce(this.uuid) }).then(response => {
                    if (response.code == 200) {
                        this.retryTimes = response.data.retryTimes
                        this.gameScore = response.data.gameScore
                        this.goldScore = response.data.goldScore
                        //score.rankInfo.topRank
                        let ranks = response.data.rankInfo.topRank
                        for(var i=0;i<ranks.length;i++){
                            ranks[i].myRank = (i+1)
                        }
                        this.score = response.data
                        this.$refs.gameOver.showGameOver()
                    }
                })
                // this.$refs.gameOver.showGameOver()
            },
            addScore(line){
                this.lines = this.lines + line
                reportScore({ gameType: this.$gameType, difficulty: this.$difficulty,currentSteps: line, nonce:handleNonce(this.uuid)}).then(response=>{
                    if(response.code == '200'){
                        this.currentGoldScore = response.data.gold
                        try{
                            let steps = Number(response.data.steps)
                            if(steps >= this.currentSteps){
                                this.currentSteps = steps
                                this.$parent.setGameScore(response.data.steps)
                            }
                        }catch(e){
                            this.$parent.setGameScore(response.data.steps)
                        }
                        
                    }
                })
            },
            updateRetryTime(retryTimes){
                this.retryTimes = retryTimes
            }
        }
    }

</script>

<style lang="scss" scoped>
.app{
        position:fixed;
        top:0;
        width: 100%;
        .top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin:1.8125rem 1rem;
            margin-bottom: 0rem;
            .back {
                // width: 1.875rem;
                // height: 1.875rem;
                width: 2.25rem;
                    height: 2.25rem;
            }
        
            .rank {
                width:2.25rem;
                height: 2.25rem;
            }
            
            .center{
                width: 17.5rem;
                margin-left: 1.25rem;
                font-size: .875rem;
                .top_line{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-self: center;
                    color:white;

                    // img{
                    //     width: 1.75rem;
                    //     height: 1.75rem;
                    // }
                }
            }
        }

        .score{
            color:white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 1rem;
            font-size: 1.875rem;
            font-weight: bold;
        }
    }
</style>