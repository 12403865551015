import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-webgpu';

import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import { Pose } from '@mediapipe/pose';
import { Camera } from './camera';
// import { Camera } from "@mediapipe/camera_utils";


tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`);

// import * as posedetection from '@tensorflow-models/pose-detection';

let startInferenceTime, numInferences = 0;
let inferenceTimeSum = 0, lastPanelUpdate = 0;

const changeSet = {
    "head": [0, 0], "l_shoulder": [0.13525806367397308, -0.5262832641601562],
    "r_shoulder": [-0.1143902838230133, -0.5146297216415405], "l_elbow": [0.12329653650522232, -0.7487189173698425],
    "r_elbow": [-0.15400373935699463, -0.7269099950790405], "l_wrist": [0.07353311777114868, -0.9740245938301086],
    "r_wrist": [-0.12536095082759857, -0.9423112869262695], "l_hip": [0.09972316026687622, -0.0017718091839924455],
    "r_hip": [-0.10023011267185211, 0.0012545179342851043],
    "l_knee": [0.08835986256599426, 0.3797764778137207],
    "r_knee": [-0.10076474398374557, 0.37073859572410583],
    "l_ankle": [0.12245306372642517, 0.6986096501350403],
    "r_ankle": [-0.08934739977121353, 0.6891912817955017],
    "key_line": [3, 4, 8, 9]
}

const downSet = {
    "head": [0, 0], "l_shoulder": [0.17947496473789215, -0.4314388930797577], "r_shoulder": [-0.17364370822906494, -0.4253755807876587], "l_elbow": [0.18145929276943207, -0.1824544221162796],
    "r_elbow": [-0.24275529384613037, -0.16379086673259735], "l_wrist": [0.19826848804950714, 0.03255913779139519], "r_wrist": [-0.22561126947402954, 0.0665191113948822],
    "l_hip": [0.11874479800462723, 0.0006190298008732498], "r_hip": [-0.11909395456314087, -0.0007494338788092136], "l_knee": [0.22764825820922852, 0.2815926671028137],
    "r_knee": [-0.21632076799869537, 0.27145546674728394], "l_ankle": [0.154276505112648, 0.5089823007583618], "r_ankle": [-0.12156800925731659, 0.5299912691116333],
    "key_line": [6, 7, 12, 11]
}

const leftSet = {
    "head": [0.4869684499314129, 0.2331511839708561],
    "l_shoulder": [0.17777210474014282, -0.522704541683197],
    "l_elbow": [0.390749454498291, -0.5093879103660583],
    "l_wrist": [0.6175645589828491, -0.4800396263599396],
    "r_shoulder": [-0.1494748443365097, -0.46974754333496094],
    "r_elbow": [-0.2167017012834549, -0.24193985760211945],
    "r_wrist": [-0.24106457829475403, -0.015245093032717705],
    "l_hip": [0.11560598760843277, -0.00011414048640290275],
    "l_knee": [0.05684017017483711, 0.4346103072166443],
    "l_ankle": [0.0940934419631958, 0.7694777250289917],
    "r_hip": [-0.11563573032617569, -0.0009133529383689165],
    "r_knee": [-0.06876738369464874, 0.40603089332580566],
    "r_ankle": [-0.01866943947970867, 0.7534489631652832],
    "key_line": [3, 4]
}

const rightSet = {
    "head": [0, 0], "l_shoulder": [0.15005595982074738, -0.5074189901351929], "r_shoulder": [-0.2061581015586853, -0.5157299041748047], "l_elbow": [0.17439916729927063, -0.28860968351364136],
    "r_elbow": [-0.4228062033653259, -0.44058048725128174], "l_wrist": [0.18739335238933563, -0.07114804536104202],
    "r_wrist": [-0.6568753123283386, -0.3707074224948883], "l_hip": [0.12121386080980301, 0.00047027753316797316],
    "r_hip": [-0.12174175679683685, -0.0002833512262441218], "l_knee": [0.13189586997032166, 0.433833509683609],
    "r_knee": [-0.14156213402748108, 0.38468191027641296], "l_ankle": [0.15325027704238892, 0.734115481376648],
    "r_ankle": [-0.11120102554559708, 0.7217639088630676], "key_line": [9, 8]
}

const standSet = {
    "head": [0, 0], "l_shoulder": [0.15732845664024353, -0.49587535858154297], "r_shoulder": [-0.15737603604793549, -0.48050034046173096], "l_elbow": [0.18614636361598969, -0.26349934935569763], "r_elbow": [-0.20986062288284302, -0.2400343418121338], "l_wrist": [0.1743585765361786, -0.06460240483283997], "r_wrist": [-0.23009145259857178, -0.03638191148638725], "l_hip": [0.11526036262512207, 0.0054597980342805386], "r_hip": [-0.1202339231967926, -0.004803840070962906], "l_knee": [0.100478895008564, 0.41541481018066406],
    "r_knee": [-0.10392510890960693, 0.38407304883003235], "l_ankle": [0.14884039759635925, 0.7717658877372742], "r_ankle": [-0.08245883882045746, 0.7275084853172302],
    "key_line": [6, 7, 12, 11, 3, 4, 8, 9]
}

function getAngle (l1, l2) {
    var line1_point1 = l1[0]
    var line1_point2 = l1[1]
    var line2_point1 = l2[0]
    var line2_point2 = l2[1]

    var dx1 = line1_point1[0] - line1_point2[0]
    var dy1 = line1_point1[1] - line1_point2[1]
    var dx2 = line2_point1[0] - line2_point2[0]
    var dy2 = line2_point1[1] - line2_point2[1]

    var angle1 = Math.atan2(dy1, dx1)
    angle1 = angle1 * 180 / Math.PI

    var angle2 = Math.atan2(dy2, dx2)
    angle2 = angle2 * 180 / Math.PI

    // console.log("angle1", angle1, "angle2", angle2)

    var insideAngle = 0
    if (angle1 * angle2 >= 0) {
        insideAngle = Math.abs(angle1 - angle2)
    } else {
        insideAngle = Math.abs(angle1) + Math.abs(angle2)
        if (insideAngle > 180) {
            insideAngle = 360 - insideAngle
        }
    }

    if (insideAngle != 0 && insideAngle != 180) {
        insideAngle = insideAngle % 180
    }
    return insideAngle
}

function getLine (pos_map) {
    var line0 = [[0, 0], [0, 0]]
    var line1 = [pos_map['l_shoulder'], pos_map['r_shoulder']]
    var line2 = [pos_map['l_hip'], pos_map['r_hip']]
    var line3 = [pos_map['l_shoulder'], pos_map['l_elbow']]
    var line4 = [pos_map['l_elbow'], pos_map['l_wrist']]
    var line5 = [pos_map['l_shoulder'], pos_map['l_hip']]
    var line6 = [pos_map['l_hip'], pos_map['l_knee']]
    var line7 = [pos_map['l_knee'], pos_map['l_ankle']]
    var line8 = [pos_map['r_shoulder'], pos_map['r_elbow']]
    var line9 = [pos_map['r_elbow'], pos_map['r_wrist']]
    var line10 = [pos_map['r_shoulder'], pos_map['r_hip']]
    var line11 = [pos_map['r_hip'], pos_map['r_knee']]
    var line12 = [pos_map['r_knee'], pos_map['r_ankle']]
    return [line0, line1, line2, line3, line4, line5, line6, line7, line8, line9, line10, line11, line12]
}

function getScore (point_map, pos_map) {
    var std_lines = getLine(point_map)
    var cmp_lines = getLine(pos_map)
    var key_lines = point_map['key_line']
    var totalScore = 0;
    for (var i = 0; i < key_lines.length; i++) {
        var key_line_id = key_lines[i]
        var socre = getAngle(std_lines[key_line_id], cmp_lines[key_line_id])
        totalScore = totalScore + socre;
    }
    totalScore = totalScore / key_lines.length;
    var score = 100 - totalScore * 100 / 180;
    // console.log(score)
    return score;
}

function onResults (results) {
    if (!results.poseLandmarks) {
        callbackF("")
        return;
    }

    var lms = results.poseLandmarks;
    var trueLms = []
    
    var maxWidth = window.innerWidth / camera.video.width
    var maxHeight = window.innerHeight / camera.video.height
    var maxScale = Math.max(maxWidth, maxHeight)

    var xSpace = camera.video.width * maxScale / 2 - window.innerWidth / 2
    var ySpace = camera.video.height * maxScale / 2 - window.innerHeight / 2

    // console.log("macScale", maxScale, "width", window.innerWidth, "height", window.innerHeight, "cw", 360 * maxScale, "ch", 270 * maxScale,"xSpace",xSpace)

    var minX = Number.MAX_VALUE
    var maxX = Number.MIN_VALUE
    var minY = Number.MAX_VALUE
    var maxY = Number.MIN_VALUE


    lms.forEach(item => {
        // var localX = (360-item.x * 360) * maxScale - xSpace
        var localX = (camera.video.width - camera.video.width * item.x) * maxScale - xSpace
        var localY = item.y * camera.video.height * maxScale - ySpace
        trueLms.push([ localX, localY])

        if(localX<minX){
            minX = localX
        }
        if(localX>maxX){
            maxX = localX
        }
        if (localY < minY) {
            minY = localY
        }
        if (localY > maxY) {
            maxY = localY
        }
    })
    // console.log(window.innerWidth + "======2=====", trueLms[15][0],360 * lms[15].x,lms[15].x)
    callbackF(trueLms, [minX, minY, maxX, maxY])

}

// async function createDetector () {
//     return posedetection.createDetector(
//         posedetection.SupportedModels.BlazePose, { runtime: "mediapipe", modelType: 'full', solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}` });
// }

function beginEstimatePosesStats () {
    startInferenceTime = (performance || Date).now();
}

function endEstimatePosesStats () {
    const endInferenceTime = (performance || Date).now();
    inferenceTimeSum += endInferenceTime - startInferenceTime;
    ++numInferences;

    const panelUpdateMilliseconds = 1000;
    if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
        const averageInferenceTime = inferenceTimeSum / numInferences;
        inferenceTimeSum = 0;
        numInferences = 0;
        let fps = 1000.0 / averageInferenceTime
        // console.log("fps:", fps)
        fpsCallback(fps)
        // stats.customFpsPanel.update(
        //     1000.0 / averageInferenceTime, 120 /* maxValue */);
        lastPanelUpdate = endInferenceTime;
    }
}

async function detectorPose () {
    // let poses = null;

    // if (detector != null) {
    //     try {
    //         poses = await detector.estimatePoses(
    //             camera.video,
    //             { maxPoses: 1, flipHorizontal: false });
    //     } catch (error) {
    //         detector.dispose();
    //         detector = null;
    //         alert(error);
    //     }
    // }

    // if (poses && poses.length > 0) {
    //     console.log("====>", poses)
    //     callbackF(poses)
    // }
    beginEstimatePosesStats()
    if (poseDetector) {
        try {
            await poseDetector.send({ image: camera.video });
        } catch (e) {
            console.error(e)
        }
    }
    endEstimatePosesStats()
    requestAnimationFrame(detectorPose)
}

async function rederResult (){
    // console.log("123")
    // if (!camera.video || !camera.video.readyState || camera.video.readyState < 2) {
    //     // await new Promise((resolve) => {
            
    //     // });
    //     let id = setInterval(() => {
    //         if (camera.video) {
    //             detectorPose()
    //             clearInterval(id)
    //         }
    //     }, 10)
    // }else{
    //     detectorPose()
    // }
    detectorPose()
}

let camera;
// let detector;
let callbackF;
let poseDetector;
let fpsCallback;

export async function start(video,callback,fpscallback){
    callbackF = callback
    fpsCallback = fpscallback
    camera = await Camera.setupCamera(video);
    // detector = await createDetector();

    poseDetector = new Pose({
        locateFile: (file) => {
            // return `/${file}`
            // if(file.indexOf(".js")==-1){
                let url1 = `https://model.kelemetaverse.com/${file}`
                console.log(url1)
                return url1
            // }
            // let url = `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`
            // console.log(url);
            // return url;
        }
    });
    poseDetector.setOptions({
        useCpuInference: false,
        modelComplexity: 0,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: false,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    });
    poseDetector.onResults(onResults);

    // let flag = false
    // if (flag){
    console.log("111111111")
    rederResult()
    // }
   
    
}