<template>
    <div>
        <van-dialog v-model:show="show" :title="$t('game.rankListTitle')" :showConfirmButton="false" style="max-height: 100%!important;overflow: auto;" :closeOnClickOverlay="true">
            <div class="rankList">
                <item v-for="(rank,index) in list" :key="index" :topRank="rank" :index="index" ></item>
                <item class="bottom" :topRank="myRank" :index="-1" style='background-color:#838383;border-radius: .625rem;'></item>
            </div>
        </van-dialog>
    </div>

</template>

<script>
    import { rankList } from '../../api/tetris/api.js'
    import item from './SortItem.vue'

    export default{
        components:{item},
        props:{
            isShow:{
                type:Boolean,
                default:false
            }
        },
        watch:{
            'isShow':{
                handler (newVal) {
                    this.show = newVal
                    this.getList()
                },
                deep: true,
                immediate: false
            }
        },
        mounted(){
            this.getList()
        },
        data(){
            return {
                show:false,
                list:[],
                myRank:{}
            }
        },
        methods:{
            getList () {
                rankList({ gameType: this.$gameType, difficulty: this.$difficulty }).then(response=>{
                    if(response.code == 200){
                        let tempList = response.data.topRank
                        for (var i = 0; i < tempList.length;i++){
                            tempList[i].myRank = i+1
                        }
                        this.list = tempList
                        this.myRank.myRank = response.data.myRank
                        this.myRank.kiwiId = response.data.myKiwiId
                        this.myRank.isoCode = response.data.myIsoCode
                        this.myRank.score = response.data.myScore
                        
                    }
                })
            },
            showRank(){
                this.show=true
            }
        }
    }

</script>

<style lang="scss" scoped>
.van-dialog__header{
    font-size: 1.125rem;
    font-weight: bold;
}
.rankList{
    margin: 1rem 1rem;
    .bottom{
        margin-top: .625rem;
    }
}
</style>