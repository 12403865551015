<template>
    <div >
        <div style="position: fixed;top:0;width: 100%;z-index: -1;">
            <video ref="video" :style="`height:${contentHeight}px`" style="-webkit-transform: scaleX(-1);transform: scaleX(-1);width: 100%; object-fit: cover;"></video>
        </div>
        
        <div ref="canvasBox" class="canvas_box" :style="`height:${contentHeight}px`">
            <div ref="myCanvas" @click="clickStop"></div>
        </div>
        
        <startBtn v-if="true"></startBtn>

        <loading v-if="showLoading" ref="loading"></loading>

        <ready v-if="showReady" ref="ready"></ready>

        <div class="leftTop">
             {{ leftAction }} <br/>
        </div>
        <!-- 右拳位置:{{ leftLoc }} 路径:{{ leftIndexArray }} -->

        <div class="rightTop">
            {{ rightAction }} <br/>
        </div>
        <!-- 左拳位置:{{ leftLoc }} 路径:{{ rightIndexArray }} -->

    </div>
</template>

<script>
    import * as Phaser from "phaser";
    import { start } from "./index.js"
    import startBtn from '../../components/start/start.vue'
    import loading from '../../components/loading/loading.vue'
    import ready from '../../components/ready/ready.vue'
    import SingleGame from '../../components/game/SingleGame.vue'
    import "vue3-circle-progress/dist/circle-progress.css";
    import CircleProgress from "vue3-circle-progress";

    export default {
    components: { startBtn, loading, ready, SingleGame, CircleProgress },
        data(){
            return {
                fullscreen:false,
                showLoading:false,
                showReady:false,
                game:null,
                bgm:null,
                kickMusic:null,
                stopIndex:0,
                rectWidth:80,
                contentHeight:window.innerHeight,
                rectGraph:null,
                centerRectScale:1,
                leftHorIndexArray:[],
                leftVeIndexArray:[],
                far:8,//最远的时候肩膀占格子树
                near:4,//最进的时候肩膀占格子树
                maxSpace:1,
                shoulderCount:6,
                minMoveSpace:5,//移动几个格子之后触发
                minMoveSpaceV:7,
                lastRightColum:0,
                lastLeftColum:0,
                leftTimeSpace:0,
                rightTimeSpace:0,
                isLeftOn:true,
                isRightOn:true,
                rightHorIndexArray:[],
                rightVeIndexArray:[],
                leftAction:"",
                leftLoc:[],
                rightAction:"",
                rightLoc:[],
            }
        },
        created () {
        },
        mounted(){
            this.$nextTick(()=>{
                // this.contentHeight = window.innerHeight
                this.initFirstGame()
            })
        },
        methods:{
            toFirstStart(){
                this.fullscreen = true
                this.showLoading = true
                this.$nextTick(()=>{
                    this.$refs.loading.startLoading()
                    this.startDect()
                })
            },
            intersect(rectA, rectB) {
                return !(
                    ((rectB[0] + rectB[2]) < rectA[0]) ||
                    (rectB[0] > (rectA[0] + rectA[2])) ||
                    ((rectB[1] + rectB[3]) < rectA[1]) ||
                    (rectB[1] > (rectA[1]+rectA[3]))
                )
            },
            initFirstGame(){
                this.createGame()
                // this.startGame()
            },
            readyComplete (personRect, landmarks){
                let shoulderLength = Math.abs(landmarks[11][0] - landmarks[12][0])
                this.rectWidth = shoulderLength / this.shoulderCount
                console.log("宽度",this.rectWidth)
                this.showReady = false
                this.isStart = true
                this.updateRect()
            },
            loadingFinish(){
                this.showLoading = false
                this.showReady = true
            },
            startDect(){
                start(this.$refs.video, (landmarks,poseRect) => {

                    if(this.showLoading){
                        if (this.isSingle) {
                            this.$refs.single.startGame()
                        }
                        this.$refs.loading.loadingComplete()
                        return
                    }

                    if(this.showReady){
                        this.$refs.ready.setBody(poseRect, landmarks)
                        return
                    }

                    if(!this.isStart){
                        return
                    }

                    if (!landmarks || landmarks.length == 0) {
                        return
                    }

                    let shoulderLength = Math.abs(landmarks[11][0] - landmarks[12][0])
                    
                    if (shoulderLength > this.rectWidth * this.far || shoulderLength < this.rectWidth*this.near){
                        return
                    }

                    this.recoBoxing(landmarks)

                    
                }, (fps) => {
                    this.fps = parseInt(fps)
                })
            },
            recoBoxing(landmarks){
                var centerLeftX = (landmarks[18][0] + landmarks[20][0]) / 2
                var centerLeftY = (landmarks[18][1] + landmarks[20][1]) / 2

                var centerRightX = (landmarks[17][0] + landmarks[19][0]) / 2
                var centerRightY = (landmarks[17][1] + landmarks[19][1]) / 2

                if(isNaN(centerLeftX)){
                    console.log("左手没有")
                }else{
                    let local = this.calculatorIndex([centerLeftX,centerLeftY])
                    let leftShoudler = this.calculatorIndex([landmarks[12][0],landmarks[12][1]])
                    this.leftLoc = [local.row,local.colum]
                    if (this.isLeftOn){//如果左手已经触发需要还原
                        // if (local.row >= leftShoudler.row && local.colum >= leftShoudler.colum){
                            this.isLeftOn = false
                            this.leftVeIndexArray = []
                            this.leftHorIndexArray = []
                            this.leftTimeSpace = new Date().getTime()
                        // }
                    }else{
                        if (local.row > Math.ceil(this.$refs.canvasBox.offsetWidth / this.rectWidth) ||
                            local.colum > Math.ceil(this.$refs.canvasBox.offsetHeight / this.rectWidth)) {
                            // this.isLeftOn = true
                        } else {
                            if (local.colum > leftShoudler.colum+this.maxSpace){//如果横向挥拳必须要在肩部上下4格
                                this.leftHorIndexArray = []//还原
                            }else{
                                if(this.leftHorIndexArray.length == 0){
                                    this.leftHorIndexArray.push(local.row)
                                }else{
                                    if(local.row <= this.leftHorIndexArray[this.leftHorIndexArray.length - 1]){//向左
                                        this.leftHorIndexArray.push(local.row)
                                    }else{
                                        this.leftHorIndexArray = [] //还原
                                    }
                                }
                            }

                            if (this.leftHorIndexArray.length >= 0 &&
                                Math.abs((this.leftHorIndexArray[0] - this.leftHorIndexArray[this.leftHorIndexArray.length-1]))>=this.minMoveSpace){
                                console.log("左上勾拳", this.leftHorIndexArray)
                                // this.leftAction = "左摆拳"
                                this.rightAction = "右摆拳:"+(new Date().getTime()-this.leftTimeSpace)
                                this.leftHorIndexArray = []
                                this.isLeftOn = true
                                this.isRightOn = true
                                this.kickMusic.play()
                            }

                            if(!this.isLeftOn){
                                if (local.colum > leftShoudler.colum + this.maxSpace) {//如果众向挥拳必须要在肩部上下4格
                                    this.leftVeIndexArray = []//还原
                                } else {
                                    if(local.colum + 1 < this.lastLeftColum){
                                        if(this.lastLeftColum > leftShoudler.colum + this.maxSpace){
                                            this.leftVeIndexArray.push(leftShoudler.colum+this.maxSpace)
                                        }else{
                                            this.leftVeIndexArray.push(this.lastLeftColum)
                                        }
                                    }
                                    if (this.leftVeIndexArray.length == 0) {
                                        this.leftVeIndexArray.push(local.colum)
                                    } else {
                                        if (local.colum <= this.leftVeIndexArray[this.leftVeIndexArray.length - 1]) {//向左
                                            this.leftVeIndexArray.push(local.colum)
                                        } else {
                                            this.leftVeIndexArray = [] //还原
                                        }
                                    }
                                }

                                if (this.leftVeIndexArray.length >= 0 && 
                                    Math.abs((this.leftVeIndexArray[0] - this.leftVeIndexArray[this.leftVeIndexArray.length-1])) >= this.minMoveSpaceV) {
                                    console.log("右勾拳", this.rightVeIndexArray, (new Date().getTime() - this.leftTimeSpace))
                                    // this.leftAction = "左勾拳"
                                    this.rightAction = "右勾拳:"+(new Date().getTime() - this.leftTimeSpace)
                                    this.leftVeIndexArray = []
                                    this.isLeftOn = true
                                    this.isRightOn = true
                                    this.kickMusic.play()
                                }
                            }
                            
                        }
                    }
                    this.lastLeftColum = local.colum
                }

                if (isNaN(centerRightX)) {
                    console.log("右手没有")
                } else {
                    let local = this.calculatorIndex([centerRightX, centerRightY])
                    let rightShoudler = this.calculatorIndex([landmarks[11][0], landmarks[11][1]])
                    this.rightLoc = [local.row, local.colum]
                    // console.log("右手当前位置", [local.row, local.colum], "右肩位置", [rightShoudler.row, rightShoudler.colum])
                    if (this.isRightOn) {//如果左手已经触发需要还原
                        // if (local.row <= rightShoudler.row && local.colum >= rightShoudler.colum) {
                            this.isRightOn = false
                            this.rightHorIndexArray = []
                            this.rightVeIndexArray = []
                            this.rightShoudlerArray = []
                            this.rightTimeSpace = new Date().getTime()
                        // }
                    } else {
                        if (local.row < 0 ||
                            local.colum > Math.ceil(this.$refs.canvasBox.offsetHeight / this.rectWidth)) {
                            // this.isRightOn = true
                        } else {
                            if (local.colum > rightShoudler.colum + this.maxSpace) {//如果横向挥拳必须要在肩部上下4格
                                this.rightHorIndexArray = []//还原
                            } else {
                                if (this.rightHorIndexArray.length == 0) {
                                    this.rightHorIndexArray.push(local.row)
                                } else {
                                    if (local.row >= this.rightHorIndexArray[this.rightHorIndexArray.length - 1]) {//向左
                                        this.rightHorIndexArray.push(local.row)
                                    } else {
                                        this.rightHorIndexArray = [] //还原
                                    }
                                }
                            }

                            if (this.rightHorIndexArray.length > 0 && 
                                Math.abs((this.rightHorIndexArray[0] - this.rightHorIndexArray[this.rightHorIndexArray.length-1])) >= this.minMoveSpace) {
                                console.log("左摆拳", this.leftHorIndexArray)
                                this.leftAction = "左摆拳:" + (new Date().getTime() - this.rightTimeSpace)
                                this.rightHorIndexArray = []
                                this.isRightOn = true
                                this.isLeftOn = true
                                this.kickMusic.play()
                            }

                            if (!this.isRightOn) {
                                if (local.colum > rightShoudler.colum + this.maxSpace) {//如果众向挥拳必须要在肩部上下4格
                                    this.rightVeIndexArray = []//还原
                                    this.rightShoudlerArray = []
                                } else {
                                    if (local.colum + 1 < this.lastRightColum) {
                                        if (this.lastRightColum > rightShoudler.colum + this.maxSpace) {
                                            this.rightVeIndexArray.push(rightShoudler.colum + this.maxSpace)
                                        } else {
                                            this.rightVeIndexArray.push(this.lastRightColum)
                                        }
                                    }
                                    if (this.rightVeIndexArray.length == 0) {
                                        this.rightVeIndexArray = [local.colum]
                                        this.rightShoudlerArray = [rightShoudler.colum]
                                    } else {
                                        if (local.colum <= this.rightVeIndexArray[this.rightVeIndexArray.length - 1]) {//向左
                                            this.rightVeIndexArray.push(local.colum)
                                            this.rightShoudlerArray.push(rightShoudler.colum)
                                        } else {
                                            this.rightVeIndexArray = [] //还原
                                            this.rightShoudlerArray = []
                                        }
                                    }
                                }
                                console.log("123", local.colum ,this.rightVeIndexArray)
                                if (this.rightVeIndexArray.length >= 0 && Math.abs((this.rightVeIndexArray[0] - this.rightVeIndexArray[this.rightVeIndexArray.length-1])) >= this.minMoveSpaceV) {
                                    console.log("左勾拳", this.rightVeIndexArray, (new Date().getTime() - this.rightTimeSpace))
                                    this.leftAction = "左勾拳:" + (new Date().getTime() - this.rightTimeSpace)
                                    this.rightVeIndexArray = []
                                    this.isRightOn = true
                                    this.isLeftOn = true
                                    this.kickMusic.play()
                                }
                            }

                        }
                        this.lastRightColum = local.colum
                    }
                }

            },
            calculatorIndex(local){
                let row = ~~(local[0]/this.rectWidth)
                let colum = ~~(local[1] / this.rectWidth)
                let flag = false
                if (local[0] > (row + (1 - this.centerRectScale) / 2) * this.rectWidth && 
                    (local[0] < (row + 1 - (1 - this.centerRectScale) / 2) * this.rectWidth) &&
                    local[1] > (colum + (1 - this.centerRectScale) / 2) * this.rectWidth &&
                    (local[1] < (colum + 1 - (1 - this.centerRectScale) / 2) * this.rectWidth)
                ){
                    flag = true
                }
                return {row:row,colum:colum,flag:flag}
            }, 
            createGame(){
                const self = this
                let config = {
                    type: Phaser.AUTO,
                    width: this.$refs.canvasBox.offsetWidth,
                    height: this.contentHeight,
                    parent: this.$refs.myCanvas,
                    transparent: true,
                    scene: {
                        preload: function(){
                            self.preload(this)
                        },
                        create: function(){
                            // this.input.on('gameobjectup', function (pointer, gameObject) {
                            //     console.log("gameobjectup")
                            //     gameObject.emit('clicked', gameObject);
                            // }, this);

                            // this.input.mouse.disableContextMenu();
                            // this.input.on('pointerup', (pointer)=>{
                            //     self.clickStop()
                            // });

                            self.create(this)
                        },
                        update: function (time, delta){
                            self.update(this, time, delta)
                        },
                    },
                };
                this.game = new Phaser.Game(config);
            },
            preload(phaser){
                // phaser.load.audio('bgm', require("../../assets/audio/bgm.mp3"));
                phaser.load.audio('kick1', require("../../assets/audio/kick.mp3"))
                // phaser.load.audio('ready', require("../../assets/audio/timedown.wav"))
                // phaser.load.audio('fail', require("../../assets/audio/fail.mp3"))
                // phaser.load.audio("miss",require("../../assets/audio/miss.mp3"))
            },
            create (phaser){

                // this.bgm = phaser.sound.add("bgm", { volume:0.2, loop: true })
                this.kickMusic = phaser.sound.add("kick1", { volume: 1, loop: false })
                // this.readyMusic = phaser.sound.add("ready", { volume: 0.9, loop: false })
                // this.failMusic = phaser.sound.add("fail", { volume: 0.9, loop: false })
                // this.missMusic = phaser.sound.add("miss", { volume: 1, loop: false })
                // this.bgm.play()

                // this.rectGraph = phaser.add.graphics({ lineStyle: { width: 2, color: 0xaa0000 }, fillStyle: { color: 0xff0000 } });
                // this.touchGraph = phaser.add.graphics({ fillStyle: { color: 0xff0000 } })

                // this.leftCycle = new Phaser.Geom.Circle(400, 300, 20);
                // this.rightCycle = new Phaser.Geom.Circle(400, 300, 20);
                
                // rectGraph.line
                // graphics.fillStyle(0x00ff00, 1);
                // graphics.fillRect(100, 100, 256, 256);

                this.rectGraph = phaser.add.graphics({ lineStyle: { width: 1, color: 0xaa0000, alpha: 0.8 }, fillStyle: { color: 0x00ff00, alpha:0.2 } });
                // var color = 0xffff00;
                // var thickness = 1;
                // var alpha = 1;
                console.log(this.rectGraph.lineStyle)
                // this.rectGraph.lineStyle(thickness, color, alpha);
                // width: this.$refs.canvasBox.offsetWidth,
                //     height: this.contentHeight,
                // this.rectGraph.strokeRect(32, 32, 256, 256);
                this.updateRect(this.rectGraph)
            },
            updateRect (rectGraph){
                console.log("updateRect")
                // let center = [this.$refs.canvasBox.offsetWidth / 2, this.contentHeight / 2]
                // rectGraph.clear()
                this.rectGraph.clear();
                let row = Math.ceil(this.$refs.canvasBox.offsetWidth / this.rectWidth)
                let colum = Math.ceil(this.contentHeight / this.rectWidth)
                for(var i=0;i<row;i++){
                    for(var j=0;j<colum;j++){
                        // let rect = new Phaser.Geom.Rectangle(i * this.rectWidth, j * this.rectWidth, this.rectWidth, this.rectWidth);
                        // console.log(rect)
                        this.rectGraph.strokeRect(i * this.rectWidth, j * this.rectWidth, this.rectWidth, this.rectWidth)
                        this.rectGraph.fillRect(i * this.rectWidth + (1 - this.centerRectScale) * this.rectWidth/2, j * this.rectWidth + (1 - this.centerRectScale) * this.rectWidth/2, this.rectWidth * this.centerRectScale, this.rectWidth * this.centerRectScale)
                    }
                }
                // console.log(this.rectGraph)
            },
            update (phaser,time, delta){

            },
        }
    }
</script>
<style scoped lang="scss">
* {
    padding: 0;
    margin: 0;
}

.leftTop{
    position: fixed;
    top: 0;
    font-weight: bold;
    font-size: 1.875rem;
    color:white
}
.rightTop{
    position: fixed;
        top: 0;
        right: 0;
        font-weight: bold;
        font-size: 1.875rem;
        color: white
}

.socre{
    position:absolute;
    background-image: url("../../assets/image/score_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 1rem;
    left: 1rem;
    width:4.375rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.125rem;
    color:white;
    font-weight: bold;
}
.canvas_box{
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.video{
    
}

.progress{
    position: absolute;
    top: 80%;
    left:50%;
    margin-left: -50px;
}

.stop{
    color:white;
    position: absolute;
    top: 84%;
    font-size: 1.25rem;
    font-weight: bold;
    left: 43.5%;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none;
}

@media screen and (orientation: portrait) {
    .canvas_box {
        width: 100%;
        height: 100%;
        // transform-origin: 0 0;
        // transform: rotateZ(90deg) translateY(-100%);
    }

    // .video {
    //     // -webkit-transform: scaleX(-1);
    //     // transform: scaleX(-1);
    //     width: 100%;
    //     object-fit: cover;

    // }
}
</style>
 
