// import * as posedetection from '@tensorflow-models/pose-detection';

// const ANCHOR_POINTS = [[0, 0, 0], [0, 1, 0], [-1, 0, 0], [-1, -1, 0]];
// const COLOR_PALETTE = [
//     '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4', '#aaffc3',
//     '#9a6324', '#000075', '#f58231', '#4363d8', '#ffd8b1', '#dcbeff', '#808000',
//     '#ffe119', '#911eb4', '#bfef45', '#f032e6', '#3cb44b', '#a9a9a9'
// ];

export class Camera{
    constructor(video){
        this.video = video
    }

    video(){
        return this.video;
    }

    static async setupCamera(video){
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            throw new Error(
                'Browser API navigator.mediaDevices.getUserMedia not available');
        }

        const videoConfig = {
            'audio': false,
            'video': {
                facingMode: 'user',
                // Only setting the video to a specified size for large screen, on
                // mobile devices accept the default size.
                width: 360,
                height: 270,
                // frameRate: {
                //     ideal: 60
                // }
            }
        }
        const stream = await navigator.mediaDevices.getUserMedia(videoConfig);
        const camera = new Camera(video);
        camera.video.srcObject = stream;

        if (camera.video.srcObject !== undefined) {
            camera.video.srcObject = stream;
        } else if (window.videoEl.mozSrcObject !== undefined) {
            camera.video.mozSrcObject = stream;
        } else if (window.URL.createObjectURL) {
            camera.video.src = window.URL.createObjectURL(stream);
        } else if (window.webkitURL) {
            camera.video.src = window.webkitURL.createObjectURL(stream);
        } else {
            camera.video.src = stream;
        }
        camera.video.playsInline = true;

        camera.video.play();

        
        await new Promise((resole)=>{
            setTimeout(function(){
                resole(1)
            },1000)
        })
        const videoWidth = camera.video.videoWidth;
        const videoHeight = camera.video.videoHeight;
        // Must set below two lines, otherwise video element doesn't show.
        console.log("===>",videoWidth,videoHeight)
        camera.video.width = videoWidth;
        camera.video.height = videoHeight;

        return camera;
    }
}