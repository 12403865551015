<template>
    <div class="loading_bg">
        <div class="u-progress-text">{{ $t("game.loading") }} {{ progress>= 100 ? 100 : progress }}%</div>
        <div class="m-progress">
            <div class="m-progress-outer">
                <div class="m-progress-inner">
                    <div :class="['u-progress-bg', { 'u-success-bg': progress >= 100 }]"
                        :style="`width: ${progress >= 100 ? 100 : progress}%;`"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name:"loading",
        data(){
            return {
                progress:0,
                timeId:null
            }
        },
        mounted () {

        },
        methods:{
            startLoading(){
                this.timeId = setInterval(()=>{
                    this.progress = this.progress+1
                    if(this.progress >= 90){
                        clearInterval(this.timeId)
                    }
                },90)
            },
            loadingComplete(){
                if(this.timeId){
                    clearInterval(this.timeId)
                }
                var time = 2000/(100-this.progress)
                var timeId = setInterval(()=>{
                    this.progress = this.progress+1
                    if(this.progress>=100){
                        clearInterval(timeId)
                        this.$parent.loadingFinish()
                    }
                },time)
            }
        }
    }
</script>

<style scoped lang="scss">
.loading_bg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/image/loading_bg.jpg');
        background-size: 100% 100%;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-content: center;
    }

    .m-progress {
        height: .9375rem;
        margin: 0 auto;
        display: flex;
        width: 15.625rem;
        
        .m-progress-outer {
            width: 100%;

            .m-progress-inner {
                width: 100%;
                border-radius: 100px;
                border: solid .1875rem white;
                border-radius: 6.25rem;
                padding: 1px;

                .u-progress-bg {
                    background: #ddfc48;
                    height: .9375rem;
                    border-radius: 100px;
                    transition: all .3s cubic-bezier(.08, .82, .17, 1);
                }

                .u-success-bg {
                    height: .9375rem;
                    background: #ddfc48;
                }
            }
        }
    }
    .u-progress-text {
        font-size: 1.125rem;
        font-weight: bold;
        color: #fff4a9;
        margin-top: 18.75rem;
    }
</style>