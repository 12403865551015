<template>
    <div>
        <!-- <van-row class="item" :style="(index % 2 == 0 ? 'background-color: #838383;' :' background-color: #a5a5a5;')"> -->
        <van-row class="item" :style="getColor(index)">
            <van-col span="6" class="left" :style="isRed?'color:black':'color:white'">
                <img v-if="index == 0" src="../../assets/image/rank_one.png"/>
                <img v-else-if="index == 1" src="../../assets/image/rank_two.png" />
                <img v-else-if="index == 2" src="../../assets/image/rank_three.png" />
                <img v-else-if="index!=-1" src="../../assets/image/rank_other.png" />
                {{ topRank.myRank == '-1' ? "-" : topRank.myRank }}
            </van-col>
            <van-col span="12" class="center" :style="isRed?'color:black':'color:white'"><img v-if="topRank.isoCode" :src="getFlag()"/>{{ topRank.kiwiId }}</van-col>
            <van-col span="6" class="right" :style="isRed?'color:black':'color:white'">{{ topRank.score }}</van-col>
        </van-row>
    </div>

</template>

<script>

    export default{
        props:{
            topRank:{
                type:Object,
                default:function(){
                    return {}
                }
            },
            index:{
                type: Number,
                default:0
            },
            isRed:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                
            }
        },
        methods:{
            getFlag(){
                return require("../../assets/image/flag/" + this.topRank.isoCode + ".png")
            },
            getColor(index){
                if(this.isRed){
                    // 'background:rgba(253,200,216,0.5)': 'background:rgba(255,255,255,0.7)'
                    if (index % 2 == 0){
                        return 'background:rgba(253,200,216,0.8'
                    }else{
                        return 'background:rgba(255,255,255,0.8)'
                    }
                }else{
                    if (index % 2 == 0) {
                        return 'background-color: #838383;'
                    } else {
                        return 'background-color: #a5a5a5;'
                    }
                }

            }
        }
    }

</script>

<style lang="scss" scoped>
.item{
    border-radius: .3125rem;
    font-size: 1.125rem;
    color: white;
    align-items: center;
    align-self: center;
    padding-top: .4875rem;
    padding-bottom: .4875rem;
    
    .blackBg{
        background-color: #838383;
    }
    .grayBg{
        background-color: #a5a5a5;
    }

    .left{
        text-align: center;
    }
    .center{
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
    }
    .right{
        text-align: center;
    }

    img{
        width: 1.25rem;
        height: 1.25rem;
    }
}
</style>