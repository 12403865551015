<template>
    <div class="app">
        
        <div class="div_con" v-if="screenOri == 1">
            <div class="div_c" v-if="status">
                <img class="notice_img" src="../../assets/image/notice_ready.png">
                <div class="camera_notice_txt">{{ allBody == 1 ? $t("game.ready.cameraBody") : $t("game.ready.cameraBody") }}</div>
                <img class="continue" @click="toNext" src="../../assets/image/notice_continue.png"/>
            </div>
        </div>

        <div  class="div_con" v-if="screenOri == 0">
            <div class="div_c" v-if="status">
                <img class="notice_img" src="../../assets/image/notice_ready_pro.jpg">
                <div class="camera_notice_txt_por">{{ allBody == 1 ? $t("game.ready.cameraBody") : $t("game.ready.cameraBody") }}
                </div>
                <img class="continue_por" @click="toNext" src="../../assets/image/notice_continue.png" />
            </div>
        </div>
        

        <div v-if="!status">
            <div :class="screenOri == 0 ? 'resizable_por' :'resizable'">
                <img v-if="allBody == 0" src="../../assets/image/game_person.png" />
                <img v-else class="half_img" src="../../assets/image/game_person_half.png"/>
            </div>
            <!-- {{ allBody == 1 ? $t("game.ready.body") : $t("game.ready.body") }}<br /> -->
            <div :class="screenOri == 0 ? 'notice_txt_por' :'notice_txt'" > {{ allBody == 0 ? $t("game.ready.body") : $t("game.ready.halfBody") }}</div>

            <img src="../../assets/image/ready_close.png" class="close" @click="close()"/>
        </div>

    </div>
</template>

<script>

    export default{
        data(){
            return {
                allBody:window.allBody,
                status:true,
                screenOri: window.screenOri,
                notice:""
            }
        },
        methods:{
            toNext(){
                this.status = false
            },
            setBody (personRect,landmarks){
                if (!this.status && personRect) {
                    if(this.allBody == 0){
                        if(window.screenOri == 1){//landscape
                            if (window.orientation == 0 || window.orientation == 180) {
                                if (personRect[0] > window.innerHeight * 0.08 &&
                                    personRect[1] > window.innerWidth * 0.1 &&
                                    personRect[2] < window.innerHeight * 0.88 &&
                                    personRect[3] < window.innerWidth * 0.9) {
                                    this.$parent.readyComplete()
                                }
                            } else {
                                if (personRect[0] > window.innerWidth * 0.34 &&
                                    personRect[1] > window.innerHeight * 0.03 &&
                                    personRect[2] < window.innerWidth * 0.64 &&
                                    personRect[3] < window.innerHeight * 0.93) {
                                    this.$parent.readyComplete()
                                }
                            }
                        }else{
                            if (personRect[0] > window.innerWidth * 0.08 &&
                                personRect[1] > window.innerHeight * 0.1 &&
                                personRect[2] < window.innerWidth * 0.88 &&
                                personRect[3] < window.innerHeight * 0.95) {
                                this.$parent.readyComplete()
                            }
                        }
                    }else{
                        var minX = Number.MAX_VALUE
                        var maxX = Number.MIN_VALUE
                        var minY = Number.MAX_VALUE
                        var maxY = Number.MIN_VALUE

                        try{
                            for(var i=0;i<25;i++){
                                var localX = landmarks[i][0]
                                var localY = landmarks[i][1]

                                if (localX < minX) {
                                    minX = localX
                                }
                                if (localX > maxX) {
                                    maxX = localX
                                }
                                if (localY < minY) {
                                    minY = localY
                                }
                                if (localY > maxY) {
                                    maxY = localY
                                }
                            }
                            personRect = [minX, minY, maxX, maxY]
                            // console.log(personRect)
                            if (personRect[0] > window.innerWidth * 0.08 &&
                                personRect[1] > window.innerHeight * 0.1 &&
                                personRect[2] < window.innerWidth * 0.88 &&
                                personRect[3] < window.innerHeight * 0.95) {
                                this.$parent.readyComplete(personRect, landmarks)
                            }
                        }catch(e){
                            
                        }
                        
                    }
                    
                }
            },
            close(){
                uni.navigateBack({
                    delta: 1
                });
            }
        }
    }
</script>

<style scoped lang="scss">
.app{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .div_con{
        width: 100%;
        height: 100%;
        
    }

    .div_c{
        width: 100%;
        height: 100%;
        background-color: white;
    }

    .camera_notice_txt{
        width: 100%;
        text-align: center;
        position:absolute;
        top:88%;
    }

    .camera_notice_txt_por{
        width: 100%;
        text-align: center;
        position: absolute;
        top:5%;
        color:white;
        font-weight: bold;
        font-size: 20px;
    }
    .notice_img {
        width: 100%;
        height: 100%;
    }

    .continue{
        position: absolute;
        top: 86%;
        right:5%;
        width: 5rem;
        height: 1.875rem;
    }

    .continue_por{
        position: absolute;
        top: 86%;
        width: 9.375rem;
        left:50%;
        margin-left: -4.6875rem;
        height: 3.125rem;
    }

    .resizable {
        width: 30%;
        height: 90%;
        padding: 0.5em;
        background: rgba(255, 255, 255, 0);
        z-index: 299;
        box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
        border-radius: .625rem;
        background-size: 6px 20px, 20px 6px, 6px 20px, 20px 6px;
        position: fixed;
        top: 3%;
        left: 34%;

        img {
            height: 80vh;
            margin-top: 5vh;
        }

        
    }

        .resizable_por {
            width: 80%;
            height: 80%;
            padding: 0.5em;
            background: rgba(255, 255, 255, 0);
            z-index: 299;
            border-radius: .625rem;
            box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
            background-size: 6px 20px, 20px 6px, 6px 20px, 20px 6px;
            position: fixed;
            top: 10%;
            left: 8%;
        
            img {
                height: 80%;
                margin-top: 15%;
            }

            .half_img {
                width: 80%;
                height: 65%;
                margin-top: 70%;
            }
        }


    .notice_txt{
        position: absolute;
        left: 5%;
        width: 20%;
        top: 45%;
        color:white;
        font-weight: bold;
        z-index: 300;
        font-size: 1.125rem;
    }

    .notice_txt_por{
        position: absolute;
            width: 100%;
            top: 5%;
            color: white;
            font-weight: bold;
            z-index: 300;
            font-size: 1.125rem;
    }

}
.close{
    width: 1.875rem;
    height: 1.875rem;
    position: absolute;
    top:5%;
    right:1rem;
    z-index: 400;
}
</style>