<template>
    <div class="main_bg" v-if="showBtn">
        <div class="bg">
            <van-button @click="toStartGame" round class="accept">{{ $t("game.startGame") }}</van-button>
        </div>
        
    </div>
</template>

<script>
    export default{
        data(){
            return {
                showBtn:true
            }
        },
        methods:{
            toStartGame(){
                this.showBtn = false
                this.$parent.toFirstStart()
            }
        }
    }
</script>

<style scoped lang="scss">
.main_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
}
.bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/image/loading_bg.jpg');
    background-size: 100% 100%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
}
.accept {
    position: fixed;
    left: 50%;
    top: 70%;
    margin-left: -6.25rem;
    width: 12.5rem;
    height: 3.75rem;
    background-image:url("../../assets/image/start_bg.png");
    background-size: 100% 100%;
    border: 0px;
    color: white;
    font-size: 1.625rem;
    font-weight: bold;
    margin-top: -1.25rem;
    
}
</style>